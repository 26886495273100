import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Mapa from "../components/map";
import Footer from "../components/footer";
import { StaticQuery, graphql } from "gatsby";





const TracksPage = () => (
  <StaticQuery
  query={graphql`
      query{
        strapiTrasy{
          Content
        }
      }
  `}
  render={(data) => (
    <Layout >
    <section  className="hero container"><Hero /></section>
    <section className="container tracks">{data.strapiTrasy.Content}</section>
    <section id="mapa" className="container"><Mapa /> </section>
    <Footer />
   
  </Layout>
    )}
    />
  
);


export default TracksPage;